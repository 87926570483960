@import '../variables.css';

.contentLabel {
  display: flex;
  color: var(--color-normal--contentLabel);
  width: calc(100% - 4rem);
}

.contentLabel.mod-section {
  color: var(--color-onSection--contentLabel);
  text-transform: uppercase;
}

.contentLabel > * {
  flex-grow: 0;
}

.contentLabel > :nth-child(1) {
  margin-right: 0.4rem;
  font-size: 1.4em;
  white-space: nowrap;
}

.contentLabel.mod-section > :nth-child(1) {
  font-weight: 400;
}

.contentLabel > :nth-child(2) {
  font-size: 1.4em;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.contentLabel.mod-finalize > :nth-child(1) {
  font-size: 1.4em;
  line-height: 1.4;
}

.contentHead.mod-block .contentLabel {
  padding-left: 1rem;
}
