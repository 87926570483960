@import '../variables.css';

.icon {
  fill: var(--color-fill--icon);
}

.icon.mod-arrowdown {
  width: 1.6rem;
  height: 1.6rem;
  fill: var(--color-fill--icon-arrow);
}

.icon.mod-checked {
  width: 2rem;
  height: 1.4rem;
  fill: var(--color-fill--icon-checked);
}

.icon.mod-close {
  width: 3.2rem;
  height: 3.2rem;
  fill: var(--color-fill--icon-close);
}

.icon.mod-zoom {
  width: 2rem;
  height: 2rem;
  fill: var(--color-fill--icon-zoom);
}

.icon.mod-copy {
  width: 3.6rem;
  height: 3.6rem;
  fill: var(--color-fill--icon-share);
}

.icon.mod-edit {
  width: 1.4rem;
  height: 1.4rem;
  fill: var(--color-fill--icon-edit);
}

.icon.mod-plus {
  width: 1.6rem;
  height: 1.6rem;
  fill: var(--color-fill--icon-plus);
}

.icon.mod-cart {
  width: 2rem;
  height: 2rem;
  fill: inherit;
}

.icon.mod-email {
  width: 1.9rem;
  height: 2rem;
  fill: inherit;
}

.icon.mod-info {
  width: 2rem;
  height: 2rem;
  fill: var(--color-fill--icon-info);
}

.icon.mod-upload {
  width: 2rem;
  height: 2rem;
  fill: var(--color-fill--icon-upload);
}

.icon.mod-select {
  width: 3rem;
  height: 2.4rem;
  fill: var(--color-fill--icon-upload);
}

.icon.mod-wheel {
  width: 2rem;
  height: 2rem;
}

.button.mod-onlyIcon .icon {
  width: 100%;
  height: 100%;
}

.icon.mod-eye {
  width: 1.6rem;
  height: 1.6rem;
  fill: #fff;
}
