@import '../variables.css';

.textareaInput-input {
  padding: 1rem;
  border: 0.1rem solid var(--borderColor-normal--textareaInput);
  height: calc(1.6em * 5);
  line-height: 1.4;
  font-size: 1.6em;
  width: 100%;
}

.textareaInput-input:focus {
  outline: 0;
  border-color: var(--borderColor-focus--textareaInput);
}
.textareaInput-input:focus + .subline {
  color: var(--borderColor-focus--textareaInput);
}
