@media print {
  .layout {
    background: none;
  }

  .is-finalize .overlay,
  .is-finalize .layout-header,
  .is-finalize .layout-footer,
  .is-finalize .sidebar,
  .is-finalize .finalize-footer,
  .is-finalize .finalize-header .button,
  .is-finalize .finalize-body .button,
  .is-finalize .finalize-headerVariation.mod-off,
  .is-finalize .socialIcons,
  .is-finalize .copyUrl,
  .is-finalize .productTitle .productTitle-button,
  .is-finalize .productTitle .productTitle-previewToggle,
  .is-finalize .returnToSite {
    display: none !important;
  }

  .is-finalize .layout-body {
    padding: 0;
    max-width: 100%;
    height: auto !important;
    overflow: visible;
  }

  .is-finalize .productTitle {
    position: absolute;
    top: 0;
    left: auto;
    right: 0;
    width: 40%;
    text-align: center;
    padding: 0;
    background: none;
    color: var(--color-black);
  }

  .is-finalize .preview {
    position: relative;
    float: left;
    width: 60%;
    top: auto;
    left: auto;
    background: none;
  }

  .is-finalize .finalize {
    position: relative;
    float: right;
    max-width: 100%;
    width: 40%;
    height: auto;
    padding: 0;
    top: 100px;
  }
  .previewImage {
    justify-content: flex-start;
  }

  .is-finalize .finalize a {
    text-decoration: none;
  }
  .is-finalize .finalize a:before {
    content: 'Filename: ';
  }
  .is-finalize .finalize a:after {
    content: '\aLink: ' attr(href);
    white-space: pre-line;
    word-break: break-all;
  }

  .is-finalize .finalize-header {
    display: block;
    height: auto;
    margin-bottom: 15px;
    background: none;
    text-align: center;
  }

  .is-finalize .finalize-body {
    height: auto;
  }

  .is-finalize .finalize-headerVariation.mod-on {
    top: auto;
    opacity: 1 !important;
  }

  .is-finalize .finalize .contentHead {
    padding: 0;
  }

  .is-finalize .finalize .contentHead.mod-section {
    line-height: 1;
    height: auto;
    background: none;
  }

  .is-finalize .finalize .section.mod-finalize {
    margin-top: 1.8rem;
  }

  .is-finalize .finalize .contentLabel.mod-finalize {
    padding-left: 0;
    text-transform: uppercase;
  }

  .is-finalize .finalize .contentBody.mod-block.mod-finalize {
    padding-left: 0;
  }

  .is-finalize
    .finalize
    .contentBody.mod-block.mod-finalize
    .contentBody-inner {
    padding-left: 0;
  }

  .is-finalize .finalize .contentLabel.mod-section {
    color: var(--color-midDarkGray);
    /*color: var(--color-black);*/
    font-size: 1.3em;
  }

  .is-finalize .finalize .block.mod-finalize {
    page-break-inside: avoid;
    padding-bottom: 1rem;
  }

  .is-finalize .finalize .block.mod-finalize table th {
    padding-right: 0.5rem;
  }

  .is-finalize .finalize .block.mod-finalize table tr th,
  .is-finalize .finalize .block.mod-finalize table tr td {
    border-bottom: 0.1rem solid #ccc;
  }

  .is-finalize .finalize .block.mod-finalize table tr:last-child th,
  .is-finalize .finalize .block.mod-finalize table tr:last-child td {
    border-bottom: 0.1rem solid #ccc;
  }

  .is-finalize .finalize .finalizeRow-label {
    width: 50%;
  }

  .is-finalize .finalize .finalizeRow-value {
    width: 50%;
  }

  .is-finalize .finalize-recipe {
    top: auto;
    color: var(--color-black);
  }

  .is-finalize .renderer {
    width: 100%;
    height: 100%;
    background: none;
  }

  .is-finalize .preview-images {
    display: block;
    padding-right: 1rem;
  }
}
