@import '../variables.css';

.bgSvgCover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.bgSvgCover .icon {
  width: 100%;
  height: 100%;
}
