@import '../variables.css';

.fileUpload-container {
  padding-bottom: 1rem;
}

.fileUpload-container:last-child {
  padding-bottom: 0;
}

.fileUpload {
  position: relative;
}

.fileUpload-dropzone {
  background-color: var(--backgroundColor-normal--fileUpload);
  border: 0.1rem dashed var(--borderColor-normal--fileUpload);
  position: relative;
  height: 0;
  width: 100%;
  overflow: hidden;
  padding-bottom: 56.25%; /* ratio of 16:9 */
  margin-bottom: 1rem;
}

.fileUpload-dropzone.is-off {
  display: none;
}

.fileUpload.is-active .fileUpload-dropzone {
  border-color: var(--borderColor-active--fileUpload);
}

.fileUpload.is-rejected .fileUpload-dropzone {
  border-color: var(--borderColor-rejected--fileUpload);
}

.fileUpload-dropzoneContent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.fileUpload-dropzoneMessage {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.fileUpload-dropzoneMessage-icon {
  flex-grow: 1;
  position: relative;
}

.fileUpload-dropzoneMessage-icon .icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  height: 80%;
  fill: var(--fill-icon--fileUpload);
}

.fileUpload-dropzoneMessage-text {
  flex-grow: 0;
  margin: 1rem;
  text-align: center;
  font-size: 1.6em;
  color: var(--color-normal--fileUpload);
}

.fileUpload-silhouette {
  position: relative;
}

.fileUpload-silhouetteInput {
  border: 0.1rem solid var(--borderColor-normal--fileUpload);
  border-right: 0;
  color: var(--color-normal--fileUpload);
  font-size: 1.4em;
  line-height: 3rem;
  padding: 0 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  word-break: keep-all;
  white-space: nowrap;
}

.fileUpload-silhouetteButton {
  display: inline-block;
  zoom: 1;
  line-height: normal;
  white-space: nowrap;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  -webkit-user-drag: none;
  user-select: none;
  box-sizing: border-box;
  font-family: inherit;
  font-size: 1.4rem;
  height: 3.2rem;
  text-decoration: none;
  text-transform: uppercase;
  transition: background 0.1s;
  color: white;
  background-color: var(--borderColor-normal--fileUpload);
  padding: 0 1rem;
  border: 0;
}

.fileUpload-clear {
  margin: 0;
  padding: 0;
  border: 0;
  background: #ce2a32;
  position: absolute;
  right: 0;
  top: 0;
  outline: 0;
  cursor: pointer;
  line-height: 0;
}
.fileUpload-clear:hover {
  background: #991c28;
}
.fileUpload-clear:active {
  background: #5f1119;
}
