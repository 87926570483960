@import '../variables.css';

.horizontalAlign {
  display: flex;
}

.horizontalAlign > * {
  flex-grow: 1;
}

.horizontalAlign.mod-staticRight > :last-child {
  flex-grow: 0;
}

.horizontalAlign.mod-wrap {
  flex-wrap: wrap;
}

.horizontalAlign.mod-color,
.horizontalAlign.mod-pattern {
  margin-left: -0.1rem;
}

.horizontalAlign.mod-image,
.horizontalAlign.mod-text {
  margin: 0 0 -0.5rem -0.5rem;
}
