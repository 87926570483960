.productSet {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  padding: 2vw;
  padding-bottom: 0;
}

.productSet > * {
  flex: 0 0 49%;
  margin-bottom: 2vw;
}

.productSet > *:nth-child(2n) {
  margin-left: 2%;
}

@media (min-width: 768px) {
  .productSet > * {
    flex: 0 0 32%;
    -ms-flex: 0 0 26%;
  }

  .productSet > *:nth-child(2n) {
    margin-left: 0;
  }

  .productSet > *:nth-child(3n-1) {
    margin-left: 2%;
    margin-right: 2%;
  }
}

@media (min-width: 1000px) {
  .productSet {
    padding: 2rem;
    padding-bottom: calc(2rem - 2%);
  }

  .productSet > * {
    margin-bottom: 2rem;
  }
}

@media (min-width: 1300px) {
  .productSet {
    padding: 1%;
  }

  .layout:not(.mod-fixedWidth) .productSet > * {
    flex: 0 0 23%;
    margin: 1%;
  }
}
