@import '../variables.css';

.contentHeadArrow {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.contentHeadArrow .icon {
  transform-origin: center;
  transition: transform 0.4s;
}

.contentHeadArrow.mod-on .icon {
  transform: rotate(-180deg);
}
