.reset-recipe {
  color: gray;
  font-size: 1.4em;
  font-style: italic;
  text-decoration: none;
  display: inline-block;
  margin-top: 1rem;
  text-align: center;
  width: 100%;
}

.reset-recipe:hover {
  text-decoration: underline;
  cursor: pointer;
}

@media (max-width: 999px) {
  .sidebar .reset-recipe {
    margin-top: 1.5em;
    margin-bottom: 1.5em;
  }
}

@media (min-width: 1000px) {
  .sidebar .reset-recipe {
    display: none;
  }
}
