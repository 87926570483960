@import '../variables.css';

.button {
  display: inline-block;
  zoom: 1;
  line-height: normal;
  white-space: nowrap;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  -webkit-user-drag: none;
  user-select: none;
  box-sizing: border-box;
  width: 100%;
  font-family: inherit;
  font-size: 1.4rem;
  font-weight: bold;
  letter-spacing: 0.1rem;
  height: 5rem;
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0);
  border: 0.2rem solid var(--color-normal--button);
  color: var(--color-normal--button);
  text-transform: uppercase;
  transition: background 0.1s;
}

.button:hover,
.button:focus {
  background-color: var(--color-hover--button);
  outline: 0 none;
}

.button .icon {
  vertical-align: top;
  fill: var(--color-normal--button);
  transition: fill 0.1s;
}

/* Firefox: Get rid of the inner focus border */
.button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.button.mod-black {
  background-color: var(--color-black);
  color: var(--color-white);
  border: 0;
}

.button.mod-black:hover {
  background-color: black;
}

.button.mod-gray {
  background-color: var(--color-warmWhite);
  color: var(--color-normal--button-gray);
  border: 0;
}

.button.mod-gray .icon {
  fill: var(--color-normal--button-gray);
}

.button.mod-gray:hover {
  background-color: var(--color-warmerWhite);
}

.button.mod-hasIcon .button-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.button.mod-hasIcon .icon {
  margin-right: 0.8rem;
}

.button.mod-black .icon {
  fill: white;
}

.button.mod-largeFont {
  font-size: 1.6rem;
  font-weight: 500;
}

.button.mod-action {
  height: 3.4rem;
  border: 0 none;
  color: var(--color-white);
}

.button.mod-edit,
.button.mod-small {
  height: 3rem;
  line-height: 1.4;
}

.button.mod-edit,
.button.mod-borderless {
  border: 0;
}

.button.mod-edit,
.button.mod-narrow {
  width: auto;
}

.button.mod-edit {
  margin-top: 0.5rem;
  color: var(--color-fill--icon-edit);
  transition: color 0.1s;
  padding: 0;
  font-size: 1.2rem;
}

.button.mod-edit .icon {
  fill: var(--color-fill--icon-edit);
}

.button.mod-edit:hover {
  background-color: transparent;
  color: var(--color-normal--button);
}

.button.mod-edit:hover .icon {
  fill: var(--color-normal--button);
}

.button.mod-remove {
  width: var(--width-normal--rosterActionButton);
  font-size: 1.6rem;
  background-color: var(--backgroundColor-normal--buttonRemove);
}

.button.mod-add {
  width: var(--width-normal--rosterActionButton);
  font-size: 2rem;
  background-color: var(--backgroundColor-normal--buttonAdd);
}

.button.mod-view {
  position: relative;
  height: 4.2rem;
  font-size: 1.4rem;
  background-color: var(--backgroundColor-normal--button-view);
  color: var(--color-normal--viewButton);
  border-color: var(--color-normal--viewButton);
}

.button.mod-view:hover,
.button.mod-view.is-active {
  color: var(--color-hover-active--viewButton);
  border-color: var(--color-hover-active--viewButton);
}

.button.mod-view:hover:after,
.button.mod-view.is-active:after {
  position: absolute;
  top: -0.9rem;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -0.7rem;
  content: ' ';
  border-left: 0.7rem solid transparent;
  border-right: 0.7rem solid transparent;
  border-bottom: 0.7rem solid var(--color-hover-active--viewButton);
  pointer-events: none;
}

.button.mod-productTile {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  font-size: 2.92vw;
  height: 10.4vw;
  color: var(--color-normal--buttonProductTile);
  border-color: var(--borderColor-normal--buttonProductTile);
  transition: border 0.1s;
}

.button.mod-productTile .icon {
  display: none;
  margin-right: 0.8rem;
}

@media (min-width: 480px) {
  .button.mod-productTile {
    font-size: 1.4rem;
    height: 5rem;
  }

  .button.mod-productTile .icon {
    display: block;
  }
}

@media (min-width: 1000px) {
  .button.mod-productTile {
    font-size: 1.2rem;
    height: 4rem;
  }
}

.button:hover.mod-productTile,
.productTile:hover .button.mod-productTile {
  background-color: transparent;
  color: var(--color-hover--buttonProductTile);
  border-color: var(--borderColor-hover--buttonProductTile);
}

.button.mod-onlyIcon {
  padding: 0.7rem;
  width: 5rem;
}

.button.mod-disabled {
  border-color: var(--color-gray);
  color: var(--color-gray);
  cursor: not-allowed;
}

.button.mod-rosterPreview {
  width: var(--width-normal--rosterActionButton);
  background-color: var(--color-warmWhite);
  color: var(--color-normal--button-gray);
}
.button.mod-rosterPreviewActive {
  width: var(--width-normal--rosterActionButton);
  background-color: var(--color-blue);
  color: var(--color-white);
}

.button.mod-rosterPreview .icon {
  fill: var(--color-normal--button-gray);
}
