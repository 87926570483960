@import '../variables.css';

.tileSet-row {
  display: flex;
  flex: 0 1 100%;
  flex-direction: row;
  flex-wrap: wrap;
}

.tileSet-primaryLabel {
  font-size: 1.4em;
  line-height: 2;
  display: inline-block;
}

.tileSet + .tileSet .tileSet-primaryLabel {
  margin-top: 1rem;
}
