@import '../variables.css';

.contentBody {
  position: relative;
}

.contentBody.mod-primary {
  margin: 0;
}

.contentBody.mod-block:not(.mod-finalize) {
  border-bottom: 0.1rem solid var(--borderColor-bottom--contentBody);
}

.contentBody.mod-finalize {
  padding-left: 1rem;
}

.contentBody.is-off {
  display: none;
}

.contentBody.mod-block .contentBody-inner {
  padding: 1rem;
}

.contentBody.mod-finalize .contentBody-inner {
  padding-top: 0;
}

.contentBody.is-changed .contentBody-inner {
  background-color: var(--backgroundColor-normal--blockMessage);
}

@media (min-width: 1000px) {
  .contentBody.mod-largeScreenHide {
    display: none;
  }
}
