@import '../variables.css';

.textInput-input {
  border: 0;
  padding: 0;
  vertical-align: middle;
  border-bottom: 0.1rem solid var(--borderColor-normal--textInput);
  line-height: 3.2rem;
  font-size: 1.6em;
  width: 100%;
}

.textInput-input:focus,
.textInput.mod-fullBorder .textInput-input:focus {
  outline: 0;
  border-color: var(--borderColor-focus--textInput);
}
.textInput-input:focus + .subline {
  color: var(--borderColor-focus--textInput);
}

.textInput.mod-gap {
  padding: 0 2rem;
}

.textInput.mod-fullBorder .textInput-input {
  padding-left: 1rem;
  padding-right: 1rem;
  border: 0.1rem solid var(--borderColor-normal--textInput);
}

.textInput.mod-invalid .textInput-input {
  background-color: var(--color-red);
  /* border-bottom: 0.2rem solid var(--color-red); */
}

.textInput.mod-invalid .subline {
  color: var(--color-red);
}

.textInput-primaryLabel {
  font-size: 1.4em;
  line-height: 2;
  display: inline-block;
  margin-top: 5px;
  margin-bottom: -5px;
}

.mod-turingTest {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  z-index: -1;
}
