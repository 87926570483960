@import '../../../_mizuno/client/components/variables.css';

#render-composite {
  position: absolute;
  width: 100%;
  height: 100%;
}

#render-composite.is-hidden {
  visibility: hidden;
}

.renderer .canvas-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.renderer canvas {
  max-width: 100%;
  max-height: 100%;
}

.renderer .viewNavigation {
  margin-bottom: -5.5rem;
}

.renderer .loader-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
}

@media (max-width: 999px) {
  .renderer.is-off canvas {
    height: calc(var(--height-small--productTitle) - 4rem);
  }

  .renderer.is-on .canvas-container {
    padding-top: 1rem;
    padding-bottom: 7.5rem;
  }

  .renderer.is-on .viewNavigation {
    margin-bottom: 2rem;
  }
}

@media (min-width: 1000px) {
  .layout:not(.is-finalize) .preview {
    padding-top: 1rem;
    padding-bottom: 7rem;
  }
}
