@import '../variables.css';

:root {
  --z-previewToggle--productTitle: 1;
  --z-button--productTitle: 2;
}

.productTitle {
  position: fixed;
  z-index: var(--z-logo);
  left: 0;
  padding: 0.5rem 0 0 1rem;
  height: var(--height-small--productTitle);
  width: 100%;
  transition: transform 0.3s;
}

.layout.is-productSelect .productTitle {
  display: none;
}

.productTitle-hero {
  font-size: 2em;
  display: block;
  word-wrap: normal;
  text-transform: uppercase;
}

.productTitle-productName {
  font-size: 1.5em;
  display: block;
  word-wrap: normal;
  text-transform: uppercase;
  padding: 0 5px;
}

.productTitle-price {
  font-size: 1.5em;
  display: block;
  word-wrap: normal;
  color: var(--color-gray);
}

.productTitle-note {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 40px;
  font-size: 1.4em;
  font-style: italic;
  word-wrap: normal;
  color: var(--color-gray);
}

.productTitle-button {
  position: absolute;
  left: 1rem;
  bottom: 1rem;
  width: 100%;
  height: 5rem;
  opacity: 1;
  z-index: var(--z-button--productTitle);
  transition: opacity 0.1s;
}

.productTitle-buttonVariation {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 1;
  transition: opacity 0.1s;
  width: 100%;
}

.productTitle-buttonVariation .button:first-child {
  width: 24rem;
  margin-right: 1rem;
}

.productTitle-buttonVariation.mod-on,
.layout.is-finalize .productTitle-buttonVariation.mod-off {
  opacity: 0;
  z-index: -1;
}

.layout.is-finalize .productTitle-buttonVariation.mod-on {
  opacity: 1;
  z-index: 1;
}

.productTitle-button.mod-hidden {
  opacity: 0;
  pointer-events: none;
  z-index: -1;
}

.productTitle-previewToggle {
  position: absolute;
  z-index: var(--z-previewToggle--productTitle);
  top: 0;
  right: 0;
  height: var(--height-small--productTitle);
  width: var(--height-small--productTitle);
}

.productTitle-previewToggle.is-on {
  height: calc(var(--height-small--productTitle) / 2);
  width: calc(var(--height-small--productTitle) / 2);
}

.productTitle-previewToggleInner {
  background-color: var(--backgroundColor-previewToggle--productTitle);
  width: 4rem;
  height: 4rem;
  position: absolute;
  top: 1rem;
  right: 1rem;
  user-select: none;
  cursor: default;
}

.productTitle-previewToggle .icon {
  width: 100%;
  height: 100%;
}

.productTitle .returnToSite {
  font-size: 14px;
  color: var(--color-darkerGray);
  padding-bottom: 2px;
}

.productTitle .returnToSite a {
  text-decoration: none;
  color: inherit;
}

.productTitle .returnToSite .arrow {
  font-size: 16px;
  font-weight: bold;
  padding-right: 2px;
  color: var(--color-darkestGray);
}

@media (min-width: 1000px) {
  .productTitle {
    position: absolute;
    top: 0;
    left: auto;
    right: 0;
    max-width: 38em;
    width: 38%;
    text-align: center;
    color: var(--color-black);
    background: var(--backgroundColor-large--productTitle);
    height: var(--height-large--productTitle);
    padding: 1.5rem 0 0;
  }

  .layout.is-productSelect .productTitle {
    display: block;
  }

  .productTitle-previewToggle,
  .productTitle-button {
    display: none;
  }
}

@media (max-width: 999px) {
  .productTitle-productName {
    padding: 0;
  }
  .productTitle-note {
    font-size: 1.2em;
    height: auto;
  }
}
