@import '../variables.css';

.bgContain {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}
