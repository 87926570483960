@import '../variables.css';

.selectInput-input {
  border: 0;
  padding: 0;
  vertical-align: middle;
  border-radius: 0;
  border-bottom: 0.1rem solid var(--borderColor-normal--selectInput);
  line-height: 3.2rem;
  font-size: 1.6em;
  width: auto;
  appearance: none;
  background-color: var(--backgroundColor-focus--selectInput);
  cursor: pointer;
}

.selectInput {
  position: relative;
}

.selectInput.mod-gap {
  margin: 0 2rem;
}

.selectInput.mod-fullWidth .selectInput-input {
  width: 100%;
}

.selectInput .icon.mod-arrowdown {
  position: absolute;
  right: 0.8rem;
  top: 0.9rem;
  pointer-events: none;
}

.selectInput-input:focus,
.selectInput.mod-fullBorder .selectInput-input:focus {
  outline: 0 none;
  border-color: var(--borderColor-focus--selectInput);
}
.selectInput-input:focus + .subline {
  color: var(--borderColor-focus--selectInput);
}

.selectInput-input::-ms-expand {
  /* for IE 11 */
  display: none;
}

.selectInput.mod-fullBorder .selectInput-input {
  padding-left: 1rem;
  padding-right: 1rem;
  border: 0.1rem solid var(--borderColor-normal--textInput);
}
