@import '../variables.css';

.finalizeRow-label {
  font-weight: 500;
  font-size: 1.4em;
  text-align: left;
  width: calc(100% - var(--width-value--finalizeRow));
  line-height: 1.4;
}

.finalizeRow-value {
  font-size: 1.4em;
  width: var(--width-value--finalizeRow);
}
