#error-alert {
  position: fixed;
  left: 20px;
  bottom: 20px;
  padding: 20px;
  color: white;
  background: #e74c3c;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.75);
  z-index: 9000;
}

#error-alert strong {
  font-size: 15px;
}

#error-alert ul {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 10px;
}

#error-alert li {
  display: inline;
  margin-right: 10px;
  padding-right: 5px;
  color: white;
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
  text-decoration: underline;
  cursor: pointer;
}
