@import '../variables.css';

.layout.is-productSelect .finalize-header {
  display: none;
}

.finalize {
  display: none;
  background: var(--backgroundColor-normal--finalize);
  overflow: hidden;
  transition: top 0.1s;
  padding-top: var(--height-small--productTitle);
}

.finalize.is-on {
  top: var(--height-small--productTitle);
  height: 100%;
  overflow-x: auto;
  display: block;
}

.finalize-header {
  display: none;
}

.finalize-recipe {
  font-size: 1.5rem;
  color: white;
  position: relative;
  top: -1.1rem;
  flex-grow: 1;
}

.finalize-recipe-label {
  text-transform: uppercase;
}

@media screen {
  .finalize-recipe {
    display: none;
  }
}

.finalize-body {
  width: 100%;
  height: calc(100% - var(--height-small--productTitle));
  overflow: hidden;
}

.finalize.is-on .finalize-body {
  overflow-y: auto;
}

.finalize-footer {
  padding: 1rem;
  height: var(--height-large--finalize-footer);
}

@media (min-width: 1000px) {
  .finalize {
    display: block;
    position: absolute;
    right: 0;
    top: 100vh;
    z-index: var(--z-finalize-large);
    max-width: 38em;
    width: 38%;
    height: 100%;
    bottom: auto;
    top: calc(100% - var(--height-large--finalize));
    height: calc(0% + var(--height-large--finalize));
    padding-top: 0;
  }

  .finalize.is-on {
    top: calc(var(--height-large--productTitle) - 2rem);
    overflow-x: auto;
    height: calc(100% + 2rem - var(--height-large--productTitle));
  }

  .finalize-header {
    display: block;
    background-color: var(--backgroundColor-header--finalize);
    width: 100%;
    text-align: center;
    height: var(--height-large--finalize);
    overflow: hidden;
  }

  .finalize.is-on .finalize-header {
    height: 8rem;
  }

  .finalize-headerButtons {
    display: flex;
  }

  .finalize-headerButtons > * {
    flex-basis: 0;
    flex-grow: 1;
  }

  .finalize-headerButtons > *:not(:first-child) {
    margin-left: 10px;
  }

  .finalize-headerButtons .icon {
    max-width: 14px;
    max-height: 14px;
  }

  .finalize-headerVariation {
    display: flex;
    flex-direction: column;
    position: relative;
    top: 0%;
    height: 100%;
    padding: 1rem;
  }

  .finalize.is-on .finalize-headerVariation {
    top: -100%;
  }

  .finalize-headerVariation,
  .finalize.is-on .finalize-headerVariation.mod-on {
    opacity: 1;
    transition: opacity ease-in 0.1s;
  }

  .finalize-headerVariation.mod-on,
  .finalize.is-on .finalize-headerVariation.mod-off {
    opacity: 0;
  }

  .finalize-headerVariation.mod-on {
    padding-top: 2rem;
  }

  .finalize.is-off .finalize-body {
    height: calc(100% - var(--height-large--productTitle) - 7rem);
  }

  .finalize.is-on .finalize-body {
    height: calc(100% - 8rem - 7rem);
  }

  .finalize-footer {
    position: absolute;
    z-index: -1;
    right: 0;
    bottom: 0;
    width: 100%;
    opacity: 0;
    background-color: var(--backgroundColor-large--finalize-footer);
  }

  .finalize.is-on .finalize-footer {
    z-index: 1;
    opacity: 1;
  }
}

.roster-table {
  font-size: 1.4rem;
  color: var(--color-darkestGray);
  text-align: left;
  margin-left: -2px;
}
.roster-table td:nth-of-type(1) {
  width: 5rem;
  white-space: nowrap;
  padding-right: 1rem;
}
.roster-table td:nth-of-type(2) {
  width: 7rem;
}
.roster-table td:nth-of-type(3) {
  width: 7rem;
}

.roster-total {
  font-weight: bold;
}
