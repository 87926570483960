@import '../variables.css';

.typeTab {
  position: relative;
  display: inline-block;
  margin: 0 0.1rem 0 0;
  zoom: 1;
  white-space: nowrap;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  -webkit-user-drag: none;
  user-select: none;
  box-sizing: border-box;
  width: 100%;
  font-family: inherit;
  font-size: 1.4rem;
  height: 4rem;
  font-weight: 300;
  text-decoration: none;
  color: var(--color-normal--typeTab);
  border: 0 none;
  background: var(--backgroundColor-normal--typeTab);
  background-size: cover;
}

.typeTab:hover,
.typeTab:focus {
  color: var(--color-hover--typeTab);
  outline: 0 none;
}

/* Firefox: Get rid of the inner focus border */
.typeTab::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.typeTab.is-active {
  color: var(--color-active--typeTab);
  background-color: var(--backgroundColor-active--typeTab);
  font-weight: 400;
}

.typeTab.is-active:after {
  position: absolute;
  top: 100%;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -7px;
  content: ' ';
  border: solid transparent;
  border-width: 7px;
  border-top-color: var(--backgroundColor-active--typeTab);
  pointer-events: none;
}

.typeTab.mod-patterns {
}

.typeTab.mod-custom {
}
