@import '../variables.css';

.viewNavigation {
  height: var(--height-viewNavigation);
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: -1;
  text-align: center;
  opacity: 0;
  transition: none;
}

.viewNavigationWithHelp {
  height: var(--height-viewNavigationWithHelp);
}

.renderer.is-on .viewNavigation {
  opacity: 1;
  z-index: var(--z-viewNavigation);
  transition: opacity 0.1s 0.1s;
}

.renderer.is-off .viewNavigation {
  display: none;
}

.help-text.is-desktop {
  display: none;
}

@media (min-width: 1000px) {
  .viewNavigation {
    opacity: 1;
    z-index: var(--z-viewNavigation);
  }
  .renderer.is-off .viewNavigation {
    display: block;
  }

  .viewNavigation .mod-view {
    padding: 0 3rem !important;
  }

  .help-text.is-mobile {
    display: none;
  }
  .help-text.is-desktop {
    display: inline;
  }
}

.viewNavigation > * {
  margin: 0 0.4rem;
}

.viewNavigation .view-buttons {
  display: flex;
  justify-content: center;
}

.viewNavigation .mod-view {
  width: auto;
  margin: 0 0.5rem;
  padding: 0 1rem;
}

.viewNavigation .renderer-help {
  padding-top: 0.5rem;
  font-size: 1.2rem;
  color: var(--color-darkerGray);
}

.viewNavigation .renderer-help .icon.mod-3d {
  width: 4rem;
  height: 4rem;
  vertical-align: middle;
  margin-right: 1rem;
  fill: var(--color-darkerGray);
}
