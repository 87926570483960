@import '../variables.css';

.contentHead {
  height: 4rem;
  line-height: 4rem;
  padding: 0 1rem;
}

.contentHead.mod-section {
  background-color: var(--backgroundColor-dark--contentHead);
}

.section.mod-off .contentHead.mod-section {
  border-bottom: 0.1rem solid var(--borderColor-bottom--section-contentHead);
}

.contentHead.mod-block:not(.mod-finalize) {
  border-bottom: 0.1rem solid var(--borderColor-bottom--contentHead);
}

.contentHead.mod-block:not(.mod-finalize),
.contentHead.mod-toggle {
  cursor: pointer;
}

.contentHead.mod-finalize {
  height: auto;
  line-height: 1;
}

.contentHead.mod-pointer {
  cursor: pointer;
}

.contentHead.mod-block .contentHeadArrow .icon {
  fill: var(--fill-block--contentHeadArrow);
}

.contentHead.mod-section .contentHeadArrow .icon {
  fill: var(--fill-section--contentHeadArrow);
}
