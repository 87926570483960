@import '../variables.css';

.tileLabel {
  font-size: 1.6em;
  position: relative;
  z-index: 1;
  font-weight: 300;
  text-align: center;
  color: inherit;
  line-height: 2rem;
  margin: calc(7.5em / 1.6) 1rem 0;
  transition: color 0.1s;
}

.tileLabel.mod-center {
  margin-top: 0;
  top: 50%;
  transform: translateY(-50%);
}

.tile.mod-noLabel .tileLabel {
  display: none;
}

.tile.mod-square .tileLabel {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  margin: 0;
  padding: 0 1rem 0.5rem;
}
