@import '../variables.css';

.layout.is-overlay {
  max-height: 100vh;
  overflow: hidden;
}

.layout.is-overlay .overlay {
  z-index: var(--z-overlay);
  opacity: 1;
}

.overlay {
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: var(--backgroundColor-normal--overlay);
  opacity: 0;
  transition:
    opacity 0.2s,
    z-index 0.25s 1ms;
}

.overlay-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
