@import '../variables.css';

.finalizeBlock-table {
  margin: 0;
  padding: 0;
  border: 0;
  width: 100%;
  color: var(--color--finalizeBlock-table);
}

.block.mod-finalize {
  padding-top: 1rem;
}

.block.mod-finalize + .block.mod-finalize {
  padding-top: 0;
}
