@import '../variables.css';

.socialIcons.mod-largeScreen {
  display: none;
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: var(--z-base);
}

.socialIcons.mod-smallScreen {
  display: flex;
  justify-content: space-around;
}

.socialIcons div {
  cursor: pointer;
}

.socialIcons a {
  display: block;
}

@media (min-width: 1000px) {
  .socialIcons.mod-largeScreen {
    display: block;
  }
}

@media (min-width: 1000px) {
  .socialIcons.mod-smallScreen {
    display: none;
  }
}

.socialIcons .icon {
  fill: var(--color-fill--icon-share);
}

.socialIcons div:hover .icon,
.socialIcons a:hover .icon {
  fill: var(--color-fill-hover--socialIcons);
}

.socialIcons.mod-largeScreen .icon {
  width: 3.6rem;
  height: 3.6rem;
}

.socialIcons.mod-smallScreen .icon {
  width: 4.4rem;
  height: 4.4rem;
}

.socialIcons .copyUrl-copied .icon {
  fill: var(--color-fill-active--copyUrl);
}
