@import '../variables.css';

.navigation {
  text-align: center;
  background-color: var(--backgroundColor-normal--navigation);
}

@media (max-width: 999px) {
  .layout:not(.is-productSelect) .navigation {
    position: fixed;
    top: var(--height-small--productTitle);
    z-index: var(--z-base);
    right: 0;
    width: 100%;
    transition: transform 0.3s;
  }
}

@media (min-width: 1000px) {
  .navigation {
    position: absolute;
    z-index: var(--z-base);
    top: 0;
    right: 0;
    width: 100%;
  }
}
