@import '../variables.css';

.blockChanges {
  display: flex;
  padding: 1rem 1rem 0 0;
}

.blockChanges-icon {
  flex-grow: 0;
  width: 4em;
  padding: 0 1rem;
}

.blockChanges-icon .icon {
  fill: var(--color-normal--blockMessage);
}

.blockChanges-item {
  font-size: 1.4em;
  color: var(--color-normal--blockMessage);
}
