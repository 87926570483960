:root {
  --color-black: rgba(33, 33, 33, 1);
  --color-white: rgba(255, 255, 255, 1);
  --color-warmerWhite: rgba(246, 246, 246, 1);
  --color-warmWhite: rgba(245, 245, 245, 1);
  --color-darkerWhite: rgba(250, 250, 250, 1);
  --color-darkGray: rgba(82, 82, 82, 1);
  --color-midDarkGray: rgba(91, 91, 91, 1);
  --color-darkerGray: rgba(129, 129, 129, 1);
  --color-darkestGray: rgba(110, 110, 110, 1);
  --color-gray: rgba(170, 170, 170, 1);
  --color-lightGray: rgba(210, 210, 210, 1);
  --color-lighterGray: rgba(235, 235, 235, 1);
  --color-moka: rgba(61, 65, 70, 1);
  --color-blue: rgba(17, 153, 231, 1);
  --color-darkBlue: rgba(21, 25, 35, 1);
  --color-red: rgba(204, 45, 55, 1);
  --color-green: rgba(58, 152, 104, 1);
  --color-brown: rgba(120, 75, 14, 1);
  --color-yellow: rgba(255, 247, 237, 1);

  --color-alpha50-black: rgba(33, 33, 33, 0.5);

  --color-black--button-hover: rgba(33, 33, 33, 0.2);
  --color-white--button-hover: rgba(255, 255, 255, 0.2);
  --color-gray--button-hover: rgba(170, 170, 170, 0.2);

  --backgroundColor-normal--overlay: rgb(from var(--color-black) r g b / 80%);

  --backgroundColor-normal--layout: rgba(29, 29, 29, 1);
  --backgroundColor-body--layout: var(--color-white);

  --fillColor-background--loadingIndicator: var(--color-darkerWhite);
  --fillColor-foreground--loadingIndicator: var(--color-darkerGray);

  --color-normal--button: var(--color-black);
  --color-hover--button: rgb(from var(--color-black) r g b / 20%);
  --color-normal--button-gray: var(--color-gray);
  --color-hover--button-gray: rgb(from var(--color-gray) r g b / 20%);
  --backgroundColor-normal--button-view: var(--color-warmWhite);

  --backgroundColor-normal--renderer: var(--color-warmWhite);
  --backgroundColor-normal--productSelect: var(--color-warmWhite);

  --backgroundColor-normal--preview: var(--color-warmWhite);

  --z-base: 1;
  --z-sidebar: 2;
  --z-finalize: 3;
  --z-preview: 4;
  --z-viewNavigation: 5;
  --z-logo: 6;
  --z-finalize-large: 7;
  --z-overlay: 8;
  --z-modal: 9;

  --color-normal--blockMessage: var(--color-brown);
  --backgroundColor-normal--blockMessage: var(--color-yellow);

  --height-large--productTitle: 13rem;
  --height-small--productTitle: 16rem;
  --height--navigation: 4.2rem;
  --height-viewNavigation: 6rem;
  --height-viewNavigationWithHelp: 9rem;
  --backgroundColor-previewToggle--productTitle: var(--color-alpha50-black);
  --backgroundColor-large--productTitle: var(--color-white);
  --height-large--finalize: 11rem;
  --backgroundColor-normal--finalize: var(--color-white);
  --backgroundColor-header--finalize: var(--color-white);
  --height-large--finalize-footer: 7rem;
  --backgroundColor-large--finalize-footer: var(--color-white);
  --width-value--finalizeRow: 60%;

  --backgroundColor-normal--buttonRemove: var(--color-red);
  --backgroundColor-normal--buttonAdd: var(--color-green);

  --frame-color--toggle: var(--color-darkGray);
  --highlight-color--toggle: var(--color-darkerGray);
  --label-default-color--toggle: var(--color-black);
  --label-active-color--toggle: var(--color-white);

  --backgroundColor-none--tile: var(--color-lightGray);
  --backgroundColor-normal--tile: var(--color-lightGray);
  --backgroundColor-hover--tile: var(--color-gray);
  --backgroundColor-active--tile: var(--color-darkestGray);
  --backgroundColor-disabled--tile: var(--color-darkerWhite);
  --color-none--tile: var(--color-midDarkGray);
  --color-normal--tile: var(--color-darkerGray);
  --color-hover--tile: var(--color-black);
  --color-active--tile: var(--color-white);
  --color-disabled--tile: var(--color-lightGray);
  --fill-normal--tile: var(--color-darkerGray);
  --fill-hover--tile: var(--color-black);
  --fill-active--tile: var(--color-white);
  --fill-disabled--tile: var(--color-lightGray);
  --borderColor-normal--tile: rgba(170, 170, 170, 0);
  --borderColor-hover--tile: var(--color-gray);

  --color-onDarkBg--tileLabel: var(--color-white);
  --color-onLightBg--tileLabel: var(--color-darkgray);

  --color-onSection--contentLabel: var(--color-white);
  --color-normal--contentLabel: var(--color-midDarkGray);

  --borderColor-bottom--contentHead: var(--color-lightGray);
  --borderColor-bottom--section-contentHead: var(--color-gray);
  --backgroundColor-dark--contentHead: var(--color-darkerGray);

  --fill-block--contentHeadArrow: var(--color-lightGray);
  --fill-section--contentHeadArrow: var(--color-gray);

  --borderColor-bottom--contentBody: var(--color-lighterGray);

  --color-normal--subline: var(--color-gray);

  --borderColor-normal--textInput: var(--color-gray);
  --borderColor-focus--textInput: var(--color-blue);

  --backgroundColor-focus--selectInput: var(--color-white);
  --borderColor-normal--selectInput: var(--color-gray);
  --borderColor-focus--selectInput: var(--color-blue);

  --borderColor-normal--textareaInput: var(--color-gray);
  --borderColor-focus--textareaInput: var(--color-blue);

  --color-normal--tagButton: var(--color-darkerGray);
  --backgroundColor-normal--tagButton: var(--color-darkBlue);
  --color-hover--tagButton: var(--color-white);
  --color-active--tagButton: var(--color-white);
  --backgroundColor-active--tagButton: var(--color-blue);

  --color-normal--viewButton: var(--color-gray);
  --borderColor-normal--viewButton: var(--color-gray);
  --color-hover-active--viewButton: var(--color-darkestGray);
  --borderColor-hover-active--viewButton: var(--color-darkestGray);

  --color-normal--typeTab: var(--color-darkerGray);
  --backgroundColor-normal--typeTab: var(--color-lightGray);
  --color-hover--typeTab: var(--color-black);
  --color-active--typeTab: var(--color-white);
  --backgroundColor-active--typeTab: var(--color-darkestGray);

  --color-normal--fileUpload: var(--color-darkGray);
  --borderColor-normal--fileUpload: var(--color-gray);
  --borderColor-active--fileUpload: #1199e7;
  --borderColor-rejected--fileUpload: #ef573b;
  --backgroundColor-normal--fileUpload: var(--color-lighterGray);
  --fill-icon--fileUpload: var(--color-lightGray);

  --backgroundColor-normal--navigation: var(--color-darkBlue);

  --backgroundColor-normal--productTile: var(--color-lightGray);
  --backgroundColor-hover--productTile: var(--color-gray);
  --color-normal--productTile: var(--color-darkerGray);
  --color-hover--productTile: var(--color-black);
  --color-normal--buttonProductTile: var(--color-darkerGray);
  --borderColor-normal--buttonProductTile: var(--color-darkerGray);
  --color-hover--buttonProductTile: var(--color-black);
  --borderColor-hover--buttonProductTile: var(--color-black);

  --color-fill--icon: var(--color-black);
  --color-fill--icon-arrow: var(--color-moka);
  --color-fill--icon-checked: var(--color-white);
  --color-fill--icon-close: var(--color-white);
  --color-fill--icon-zoom: var(--color-white);
  --color-fill--icon-share: var(--color-gray);
  --color-fill--icon-edit: var(--color-gray);
  --color-fill--icon-plus: var(--color-gray);
  --color-fill--icon-cart: var(--color-white);
  --color-fill--icon-info: var(--color-white);
  --color-fill--icon-upload: var(--color-white);
  --color-fill--icon-select: var(--color-white);

  --color-fill-hover--socialIcons: var(--color-darkGray);

  --color-normal--copyUrl: var(--color-gray);
  --color-hover--copyUrl: var(--color-darkGray);
  --color-active--copyUrl: var(--color-blue);
  --color-fill-active--copyUrl: var(--color-blue);
  --color-fill-hover--copyUrl: var(--color-darkGray);
  --color-fill--copyUrl: var(--color-blue);

  --width-normal--rosterActionButton: 3.4rem;

  --color--finalizeBlock-table: var(--color-darkestGray);
}
