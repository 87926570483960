@import '../variables.css';

.tile {
  flex: 1 0 auto;
  position: relative;
  z-index: 0;
  user-select: none;
  cursor: default;
  transition: background 0.1s;
}

.tile:not(.mod-color) {
  background-color: var(--backgroundColor-normal--tile);
  color: var(--color-normal--tile);
}

.tile:not(.mod-color):hover {
  background-color: var(--backgroundColor-hover--tile);
  color: var(--color-hover--tile);
}

.tile:not(.mod-color).is-active {
  background-color: var(--backgroundColor-active--tile);
  color: var(--color-active--tile);
}

.tile.mod-none {
  background-color: var(--backgroundColor-none--tile);
}

.tile.is-disabled.mod-color:after,
.tile.mod-none:after {
  color: var(--color-none--tile);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.4em;
  font-weight: 300;
  line-height: 1;
  text-transform: uppercase;
}

.tile.is-disabled.mod-color {
  overflow: hidden;
}

.tile.is-disabled.mod-color:after {
  content: 'N/A';
  padding: 0.1em 1em;
  background-color: var(--backgroundColor-none--tile);
}

.tile.mod-none.mod-color:after,
.tile.mod-none.mod-pattern:after {
  content: attr(data-label);
}

.tile.mod-pattern,
.tile.mod-image,
.tile.mod-color,
.tile.mod-panel,
.tile.mod-text {
  cursor: pointer;
}

.tile.is-disabled {
  cursor: not-allowed;
}

.tile.mod-color,
.tile.mod-panel,
.tile.mod-pattern {
  height: 4rem;
  margin: 0 0 0.1rem 0.1rem;
}

.tile.mod-panel {
  height: 20rem;
}

.tile.mod-pattern:before,
.tile.mod-image:before,
.tile.mod-panel:before,
.tile.mod-color:before {
  content: '';
  position: absolute;
  z-index: var(--z-logo);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 0.2rem solid var(--borderColor-normal--tile);
  transition: border 0.1s;
}
.tile.mod-pattern:hover:before,
.tile.mod-image:hover:before,
.tile.mod-panel:hover:before,
.tile.mod-color:not(.is-disabled):hover:before {
  border-color: var(--borderColor-hover--tile);
  border-width: 0.2rem;
}

.tile.mod-color:not(.is-disabled):before {
  border-color: var(--color-lightGray);
  border-width: 1px;
}

.tile.mod-text,
.tile.mod-image {
  flex: 0 0 calc(50% - 0.5rem);
  -ms-flex: 0 0 48%;
  height: 10rem;
  margin: 0 0 0.5rem 0.5rem;
}

.tile.mod-text {
  height: 6rem;
}
.tile.mod-text.mod-disabled {
  background: var(--color-darkerWhite);
  color: var(--color-lightGray);
  cursor: not-allowed;
}

.tile.mod-text .tile-inner {
  position: absolute;
  width: 100%;
  height: 100%;
}

.tile.mod-ghost {
  opacity: 0;
  user-select: none;
  pointer-events: none;
}

.tile.is-active .icon.mod-select {
  position: absolute;
  z-index: var(--z-modal);
  top: 0rem;
  left: 0rem;
}

.tile .bgSvgCover .icon {
  fill: var(--fill-normal--tile);
}

.tile:hover .bgSvgCover .icon {
  fill: var(--fill-hover--tile);
}

.tile.is-active .bgSvgCover .icon {
  fill: var(--fill-active--tile);
}

.tile.mod-image.mod-noLabel {
  height: 6rem;
  overflow: hidden;
}

.tile.mod-square {
  height: auto;
}

.tile.mod-bgProduct .bgCover {
  background-size: 78%;
  background-repeat: no-repeat;
  background-position: center 25%;
}

.tile.mod-bgHand .bgCover {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center bottom;
}

.tile.mod-bgOpacity .bgCover {
  opacity: 0.4;
}

.tile.mod-square .tile-inner {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 100%;
}

.tile.mod-fillIcon {
  height: 40px !important;
  border: 1px solid var(--color-lightGray);
}

.tile.mod-sportIcon {
  height: 280px !important;
  max-width: 400px;
  background: #f5f5f5;
  border: 1px solid var(--color-lightGray);
}

.tile.mod-sportIcon:hover {
  background: #f5f5f5;
}

.tile.mod-image.tile.mod-sportIcon .bgCover {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.tile.mod-fontIcon {
  height: 100px !important;
}

.tile.mod-designIcon {
  height: 140px !important;
}

.tile.mod-image.tile.mod-fontIcon .bgCover {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

@media (max-width: 999px) {
  .tile.mod-designIcon {
    height: 280px !important;
  }
  .tile.mod-image.tile.mod-designIcon .bgCover {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
}
