@import '../variables.css';

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-indicator {
  z-index: 100;
  width: 72px !important;
  height: 72px !important;
  opacity: 0.85;
  animation: spin 1s linear infinite;
}

.loading-indicator-cls-1 {
  fill: var(--fillColor-background--loadingIndicator);
}

.loading-indicator-cls-2 {
  fill: var(--fillColor-foreground--loadingIndicator);
}
