@import '../variables.css';

.subline {
  padding-top: 0.25rem;
  font-size: 1.2em;
  color: var(--color-normal--subline);
}

.subline.mod-italic {
  font-style: italic;
}
