@import '../variables.css';

.productTile {
  position: relative;
  padding: 2rem 0 22vw;
  background-color: var(--backgroundColor-normal--productTile);
  user-select: none;
  cursor: pointer;
  transition: background 0.1s;
  text-align: center;
}

@media (min-width: 480px) {
  .productTile {
    padding-bottom: 8.4rem;
  }
}

@media (min-width: 1000px) {
  .productTile {
    padding-bottom: 7rem;
  }
}

.productTile:hover {
  background-color: var(--backgroundColor-hover--productTile);
}

.productTile-image {
  padding-bottom: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto 95%;
}

.productTile-text {
  font-size: 4.1vw;
  line-height: 1.05;
  color: var(--color-normal--productTile);
}

@media (min-width: 480px) {
  .productTile-text {
    font-size: 2rem;
  }
}

@media (min-width: 480px) {
  .productTile-text {
    font-size: 1.6rem;
  }
}

.productTile:hover .productTile-text {
  color: var(--color-hover--productTile);
}

.productTile-button {
  position: absolute;
  left: 1.8rem;
  right: 1.8rem;
  bottom: 2rem;
}
