@import '../variables.css';

.navigationTab {
  position: relative;
  display: inline-block;
  padding: 0 1rem;
  zoom: 1;
  white-space: nowrap;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  -webkit-user-drag: none;
  user-select: none;
  box-sizing: border-box;
  width: auto;
  font-family: inherit;
  font-size: 1.4em;
  height: 4.2rem;
  font-weight: 400;
  text-decoration: none;
  color: var(--color-normal--tagButton);
  border: 0 none;
  background: var(--backgroundColor-normal--tagButton);
  text-transform: uppercase;
  transition: background 0.1s;
}

.navigationTab:hover,
.navigationTab:focus {
  color: var(--color-white);
  outline: 0 none;
}

/* Firefox: Get rid of the inner focus border */
.navigationTab::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.navigationTab.is-active {
  color: var(--color-active--tagButton);
  background: var(--backgroundColor-active--tagButton);
  font-weight: 700;
}

.navigationTab.is-active:after {
  position: absolute;
  top: 100%;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -7px;
  content: ' ';
  border: solid transparent;
  border-width: 7px;
  border-top-color: var(--backgroundColor-active--tagButton);
  pointer-events: none;
}
