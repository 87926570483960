@import './variables.css';
@import './Print.css';

html {
  font-size: 62.5%;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
}

.layout {
  background-color: var(--backgroundColor-normal--layout);
}

.layout-header {
  position: fixed;
  z-index: var(--z-finalize);
  top: 0;
  left: 0;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
}

.layout:not(.is-productSelect):not(.is-finalize) .layout-header {
  transition: transform 0.3s;
}

.layout-body {
  position: relative;
  overflow: hidden;
  width: 100%;
  margin: 0 auto;
  background-color: var(--backgroundColor-body--layout);
}

.layout-footer {
  width: 100%;
  margin: 0 auto;
}

.sidebar {
  position: relative;
  z-index: var(--z-sidebar);
  height: 100%;
}

.layout.is-finalize .sidebar {
  display: none;
}

.sidebar-body {
  overflow: auto;
  height: calc(100%);
  width: 100%;
}

@media (max-width: 999px) {
  .renderer.is-on {
    width: 100vw;
    height: 100vh;
  }

  .layout.is-productSelect .layout-body {
    display: flex;
    flex-direction: column;
  }

  .layout.is-productSelect .viewer {
    order: 2;
  }

  .layout.is-productSelect .sidebar {
    order: 1;
  }

  .layout:not(.is-productSelect) .sidebar-body {
    padding-top: calc(
      var(--height-small--productTitle) + var(--height--navigation)
    );
  }
}

@media (min-width: 1000px) {
  .layout-header {
    position: relative;
  }

  .layout-body {
    height: 100vh;
  }
  .sidebar {
    position: absolute;
    max-width: 38em;
    width: 38%;
    left: calc(100% - 38em);
    right: 0;
    top: var(--height-large--productTitle);
    height: calc(
      100% - var(--height-large--productTitle) - var(--height-large--finalize)
    );
    overflow-y: auto;
  }
  .sidebar-body {
    padding-top: var(--height--navigation);
  }

  .layout.is-productSelect .sidebar {
    height: calc(100% - var(--height-large--productTitle));
  }

  .layout.is-finalize .sidebar {
    display: block;
  }

  .layout.is-productSelect .finalize {
    display: none;
  }
}

.block {
  background-color: var(--color-white);
}

.preview {
  position: fixed;
  z-index: var(--z-preview);
  width: 100%;
  height: auto;
  left: 0;
  background-color: var(--backgroundColor-normal--preview);
}

.layout:not(.is-productSelect):not(.is-finalize) .preview {
  transition: transform 0.3s;
}

@media (min-width: 1000px) {
  .preview {
    position: absolute;
    top: 0;
  }
}

.layout.is-productSelect .preview {
  visibility: hidden;
}

.renderer {
  position: relative;
  width: 12rem;
  height: 16rem;
  left: 100%;
  transform: translateX(-100%);
  background-color: var(--backgroundColor-normal--renderer);
}

.layout:not(.is-productSelect):not(.is-finalize) .renderer {
  transition:
    width 0.1s,
    height 0.1s;
}

.renderer-viewport {
  position: absolute;
  width: 100%;
  height: 100%;
}

.renderer.is-on .renderer-viewport {
  height: 100%;
}

.renderer.is-hidden {
  visibility: hidden;
}

.renderer-viewport > * {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.renderer-viewport .loading-indicator {
  margin-left: -36px;
  margin-top: -36px;
}

.renderer-viewport > * > canvas {
  width: 100%;
  height: 100%;
  outline: none;
}

.preview-images-outer {
  width: 100%;
  height: 100%;

  /* needed for uniforms */
  position: absolute;
  top: 0;
}

.preview-images {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.preview-images-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10vh 10vw;
}

.preview-image-large {
  height: 50vh;
}

.preview-images-small {
  margin-top: 5vh;
  height: 25vh;
  display: flex;
}

.preview-image-small {
  height: 100%;
}

.preview-image-small:not(:first-child) {
  margin-left: 5vh;
}

.preview-image-vertical:not(:first-child) {
  margin-top: 5vh;
}

@media (max-width: 999px) {
  .preview-images .preview-image-corner {
    display: none;
  }

  .layout:not(.is-preview-on) .preview-image-corner {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    height: 12rem;
    width: auto;
    margin-top: 2em;
  }
}

@media (min-width: 1000px) {
  .preview {
    max-width: calc(100% - 38em);
    width: 100%;
    height: 100%;
  }

  .renderer {
    width: 100%;
    height: 100%;
    left: 0;
    transform: translateX(0);
  }

  .renderer.is-on .renderer-viewport,
  .renderer-viewport {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
  }
}

.productSelect {
  background-color: var(--backgroundColor-normal--productSelect);
}

.layout:not(.is-productSelect) .productSelect {
  display: none;
}

@media (min-width: 1000px) {
  .productSelect {
    overflow-y: auto;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 38em);
  }
}

#viewer {
  float: left;
  white-space: pre;
}

#viewer .key {
  font-family: monospace;
  font-size: 12px;
  padding-right: 10px;
}

#viewer .value {
  font-size: 14px;
  font-weight: bold;
  color: #444;
}

#viewer .null-value {
  font-size: 12px;
  font-style: italic;
  color: #444;
}

#viewer td {
  vertical-align: top;
}

#viewer tr:nth-child(odd) {
  background: #eee;
}

select {
  width: 200px;
}

.field + .field {
  margin-top: 10px;
}

.field label {
  font-size: 12px;
}

.field .error {
  color: indianred;
  font-size: 0.75em;
  padding-top: 5px;
}

.roster h5 {
  display: inline-block;
  line-height: 0;
  padding-right: 5px;
}

.roster .add {
  margin-top: 20px;
}

.roster .remove {
  color: rgb(0, 0, 238);
  cursor: pointer;
}

a {
  color: var(--color-blue);
}

h1 a {
  color: black;
  text-decoration: none;
}

.graphics-control {
  padding: 1rem;
}

.RangeControl strong {
  color: var(--color-darkerGray);
}

.RangeControl .inputs {
  display: flex;
  align-items: center;
}

.RangeControl .unit {
  font-weight: bold;
  font-size: 14px;
  padding-left: 2px;
}

.RangeControl input[type='number'] {
  width: 60px;
}

.RangeControl input[type='range'] {
  margin-left: 10px;
}
